
import { defineComponent } from "vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "cooperative-policy-summary-table-component",
  components: { GoBackButton },
  props: {
    widgetClasses: String,
    policySummary: Object,
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    downloadPolicyDocuments(publicId, reference) {
      const submitButton1 = document.getElementById("policy_download_btn");

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        // Delete cart item
        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.setResponseType("blob");
          ApiService.get(`${variables.POLICIES_ROUTE}/${publicId}/documents`)
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");

              // console.log(response.data);
              const blob = new Blob([response.data], {
                type: "application/octet-stream",
              });

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download =
                "Motor-insurance(" + reference + ")-policy-documents.zip";

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(function(error) {
              console.log(error);
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },
  },
});
